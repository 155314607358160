body {
  font-size: 13px;
  line-height: 1.5;
  font-family: 'Rubik', sans-serif;
  background-color: #021321;
  background-image: url('../src/assets/body-bg.jpg');
  background-attachment: fixed;
  background-size: cover;
}

a {
  color: #e5f3f6;
  text-decoration: none;
  -webkit-transition: all .4s ease-out 0s;
  transition: all .4s ease-out 0s;

}

.logo img {
  max-width: 100%;
}

ul {
  padding: 0;
}

button {
  -webkit-transition: all .4s ease-out 0s;
  transition: all .4s ease-out 0s;
}

a:hover,
button:hover {
  color: #33D1EB;
  text-decoration: none;
  -webkit-transition: all .4s ease-out 0s;
  transition: all .4s ease-out 0s;
}

.green-100 {
  color: #33D1EB;
}

ul {
  margin: 0;
}

li {
  list-style: none;
}

body:before {
  display: none;
  content: "";
  background: url(../src/assets/bg-1.png) no-repeat 0 0 transparent;
  width: 60%;
  height: 100%;
  background-size: cover;
  right: 0;
  mix-blend-mode: screen;
  z-index: -1;
  position: fixed;
  animation: animation-02 19s infinite linear alternate;
  max-width: 1220px;
}

@keyframes animation-01 {
  0% {
    transform: translateY(0)
  }

  25% {
    transform: translateY(-10px)
  }

  50% {
    transform: translateY(0)
  }

  75% {
    transform: translateY(10px)
  }

  100% {
    transform: translateY(0)
  }
}

@keyframes animation-03 {
  0% {
    transform: translateY(0)
  }

  25% {
    transform: translateY(-20px)
  }

  50% {
    transform: translateY(0)
  }

  75% {
    transform: translateY(20px)
  }

  100% {
    transform: translateY(0)
  }
}


.section-cards-4:before {
  content: "";
  background: url(../src/assets/planet.png) no-repeat 0 0 transparent;
  position: absolute;
  width: 30%;
  height: 210px;
  background-size: cover;
  z-index: -1;
  top: unset;
  right: 0%;
  bottom: 30%;
  max-width: 140px;
  animation: animation-03 9s infinite linear alternate;
  opacity: 0;
}

header {
  border-bottom: 1px solid #ffffff1f;
}

header .container-fluid {
  max-width: 1700px;
}

.title {
  color: white;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.title i {
  color: #33D1EB;
  margin-right: 4px;
}

.large-title {
  font-size: 22px;
}

.offcanvas-menu {
  background: #070f25;
  max-width: 80%;
}

.offcanvas-menu:after {
  mix-blend-mode: screen;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../src/assets/bg-1.png) no-repeat top center transparent;
  background-size: cover;
  top: 0;
  z-index: -1;
  opacity: 0.3;
}

.offcanvas-menu:before {
  background: url(../src/assets/body-bg.png) no-repeat 0 0 #021321;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.6;
}

.mobile-nav {
  display: block;
  margin-bottom: 2rem;
}

.mobile-nav .nav-item a {
  padding: 16px 0px;
  display: block;
  border-bottom: 1px solid #ffffff14;
}

.offcanvas-header {
  color: white;
}

.offcanvas-header h5 {
  font-weight: 500;
}

.offcanvas-body {
  padding: 0px 20px;
}

.close-button {
  color: #29fff1;
  font-size: 18px;
  background: transparent;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #29fff1;
  line-height: 29px;
  opacity: 0.8;
  padding: 0;
  text-align: center;
}

.close-button:hover {
  opacity: 1;
}

.switch-mode {
  width: 44px;
  height: 44px;
  border: 0;
  padding: 0;
  margin: 0px 20px;
  background: #1950ac;
  color: white;
  border-radius: 50%;
}

.switch-mode:hover {
  background: #0f45a1;
}

nav.main-nav {
  margin: 0;
  padding: 0;
}

.nav-item a {
  position: relative;
  padding: 34px 1rem;
  font-size: 14px;
  color: white;
  display: block;
}

.nav-item a:hover,
.nav-item a.active {
  color: #33D1EB;
}

.nav-item a:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 1px;
  left: 0;
  background-image: linear-gradient(to right, #080a1700 0%, #28b1be 51%, #00000000 100%);
  transition: 0.5s;
  right: 0;
  margin: 0px auto;
}

.nav-item a.active:before {
  width: 80%;
  transition: 0.5s;
}

.navbar {
  margin: 0;
}

.nav-item {
  list-style: none;
}

.button-primary {
  height: 44px;
  border: 2px solid #33D1EB;
  padding: 14px 2rem;
  border-radius: 36px;
  color: #33D1EB;
  font-weight: 500;
  line-height: 1;
  background: transparent;
  font-size: 14px;
  position: relative;
  background-size: 100% auto;

}

.button-primary:hover {
  background: #1950ac;
  color: white !important;
  border-color: transparent;
  background: -webkit-gradient(linear,left top,right top,from(#be0eca),color-stop(50%,#2d4def),to(#223d9d));
  background: linear-gradient(to right,#be0eca 0%,#2d4def 50%,#223d9d 100%);
  background-size: 200% auto;
}

.button-primary:disabled {
  background: inherit;
  border-color: #1ba777;
  color: #1ba777;
  opacity: .6;
}

.button-primary p {
  margin: 0;
}

.button-primary p+p {
  font-weight: 300;
  font-size: 10px;
  position: absolute;
  bottom: -18px;
}

.claim-all-button {
  font-size: 13px;
}

.color-green {
  border-color: #1BA777;
  color: #1BA777;
}

.color-green:hover {
  background: #1ba777;
  color: white !important;
  border-color: #1ba777;
}

.button-secondary {
  background: #2171d4;
  color: #ffffff;
  font-weight: 500;
  padding: 12px 0;
  border-radius: 36px;
  border: 2px solid transparent;
  text-align: center;
  font-size: 13px;
}

.offcanvas-menu .button-secondary {
  margin-top: 2rem;
}

.CookieConsent div:nth-child(2){
  height:100%;
  margin: auto 10px auto auto;
}

.CookieConsent a{
  color: #33D1EB;
}

.button-secondary:hover {
  color: white !important;
  background: #185eb3;
}

.button-switch {
  background: #8c20b4;
  color: #ffffff;
  font-weight: 500;
  padding: 12px 0;
  border-radius: 36px;
  border: 2px solid transparent;
}

.button-switch:hover {
  background: #7c19a0;
  color: #ffffff !important;
}

.menu-control:before {
  content: "\e6c2";
  font-family: 'themify';
}

.menu-control {
  background: none;
  border: none;
  color: #33D1EB !important;
  font-size: 21px;
  padding: 0 14px;
  background: transparent !important;
}

main {
  padding: 3rem 0;
  position: relative;
  overflow: hidden;
  min-height: 800px;
}

.date-filter {
  background: #071A2A;
  padding: 0;
  margin: 0;
  border-radius: 36px;
}

.filter-title {
  color: white;
  line-height: 44px;
  padding: 0px 1.4rem;
  border-radius: 36px;
  font-size: 14px;
}

.filter-options {
  padding: 0;
}

.filter-options {
  justify-content: flex-start !important;
  display: flex !important;
  align-items: center !important;
  padding-right: 16px;
}

.filter-options li button {
  background: transparent;
  color: white;
  border: 0;
  padding: 0px 12px;
  line-height: 44px;
  font-size: 15px;
}

.filter-options li button.active {
  color: #33D1EB;
}

.section-filter {
  color: #BBD2E4;
  font-size: 14px;
}

.version-info li {
  padding-left: 2rem;
  font-size: 14px;
  align-items: center !important;
  display: flex;
}

.version-info li img,
.version-info li svg {
  margin-right: 6px;
}

.card-style-1 {
  background: #091126e0;
  padding: 26px 26px 0px 26px;
  border-radius: 16px;
}

.card-title {
  color: #8ba3a3;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.card-value {
  font-size: 24px;
  color: white;
}

.sub-text {
  color: #33D1EB;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.card-right img {
  position: absolute;
  right: 20px;
  bottom: 0;
}

.card-style-2 {
  background: #061929c9;
  padding: 26px;
  border-radius: 16px;
}

.card-style-2 .card-value {
  font-size: 15px;
  color: #33D1EB;
}

.card-top {
  margin-bottom: 30px;
}

.chart img {
  max-width: 100%;
  height: auto;
}

.faq-accordion {
  background: transparent !important;
  counter-reset: accordioncounter;
}

.faq-accordion .accordion-item {
  background: #0c0e23a8;
  color: white;
  font-size: 14px;
  line-height: 1.8;
  font-weight: 300;
  border: 0;
  margin-bottom: 15px;
}

.faq-accordion .accordion-item .accordion-body {
  border: 0;
  padding: 20px
}

.faq-accordion .accordion-header button {
  background: #0c0e23a8;
  color: #0fd9ff;
  padding: 24px 52px 24px 20px;
  border: 0;
  box-shadow: none;
  font-size: 17px;
}

.faq-accordion .accordion-header button:after {
  content: "\f078";
  font-family: 'FontAwesome';
  background: no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  text-align: center;
  right: 20px;
  line-height: 24px;
}

.faq-accordion .accordion-header button:before {
  counter-increment: accordioncounter;
  content: counters(accordioncounter, ".") ".";
  margin: 0 1rem;
  color: #fff;
}

/* Charts */
.chart-right canvas {
  max-width: 100px;
}

/* Charts */


/* footer */
.footer {
  border-top: 1px solid #ffffff1f;
  color: #BBD2E4;
  padding: 20px 0;
  font-size: 14px;
}

/* footer */

/* modal */
.modal-body {
  padding: 0 34px 34px 34px;
}

.modal-content {
  background: #0a0f1e;
  color: white;
}

.modal-title {
  color: #37CCC7;
  font-weight: 500;
  font-size: 16px !important;
}

.modal-title .fa-arrow-right-to-bracket {
  margin-right: 5px;
}

.modal-header {
  border: 0;
  padding: 26px 34px 16px 34px;
}

.modal-footer {
  border: 0;
}

.asset-logo {
  height: 50px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  margin-right: 12px;
}

.asset-logo img {
  max-width: 26px;
}

.box-title {
  background: #080c17;
  padding: 20px 30px;
  border-radius: 0px;
  position: relative;
}

.bond-asset {
  position: absolute;
  right: -20px;
  top: 23px;
  z-index: 1;
}

.bond-asset img {
  max-height: 38px;
  width: auto;
}

.box-title p {
  margin: 0;
  color: #37CCC7;
  font-size: 14px;
}

.box-title h5 {
  margin: 0;
  font-size: 14px;
}

.close-modal {
  background: transparent;
  box-shadow: none !important;
  border: 0;
  color: #37ccc7;
  font-size: 26px;
}

.close-modal:hover {
  color: #10e8e0;
}

.info-alert {
  text-align: center;
  max-width: 440px;
  margin: 0px auto;
  padding: 2rem 0;
}

button.block-button {
  background: #1D57BE;
  width: 100%;
  border: 0;
  display: block;
  color: white;
  padding: 14px 20px;
  border-radius: 4px;
}

button.block-button:hover {
  color: white;
  background: #0d44a6;
}

.bond-details {
  margin-top: 20px;
  padding: 0;
}

.bond-details li {
  justify-content: space-between !important;
  display: flex !important;
  line-height: 30px;
  color: #DCDCDC;
}

ul.bond-details li:nth-child(2) {
  color: #37ccc7;
  font-weight: 500;
}

.modal-backdrop {
  opacity: 0.85 !important;
  background: #010102 !important;
}

/* modal */

/* forms */
.form-control {
  background: transparent;
  border: 1px solid #1d1f21;
  color: white;
  font-size: 14px;
}

.form-control:focus {
  background: transparent;
  border-color: #ffffffc7;
  box-shadow: none;
  color: white;
}

.input-asset-name {
  position: absolute;
  right: 80px;
  top: 0;
  height: 58px;
  line-height: 58px;
  color: #6e7072;
}

.input-button {
  position: absolute;
  right: 20px;
  top: 12px;
  background: transparent;
  border: 0;
  color: #37ccc7;
  font-size: 15px;
}

.input-button:hover {
  color: white !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* forms */

/* warnings */
.warning-connection {
  text-align: center;
  color: white;
  padding: 4rem 1rem;
  background: #0a112ad9;
  border-radius: 9px;
}

.bond-list .warning-connection {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.warning-connection h3 {
  color: #31cce5;
  font-weight: 500;
  font-size: 2rem;
}

.warning-connection .warning-message {
  max-width: 420px;
  margin: 0px auto;
  padding-bottom: 2rem;
  font-size: 15px;
}

.warning-connection i {
  font-size: 3rem;
  color: #31cce5;
  margin-bottom: 1rem;
}

/* warnings */

/* toast */
.sc-kuWgmH:before {
  background: #1d263f !important;
}

.sc-gjJAnY {
  display: none;
}

.sc-kPTPQs {
  color: white;
}

.sc-dicizt {
  color: white !important;
}

.sc-dcrmVg {
  background: #151b24b0 !important;
}

.sc-jWActB button {
  background: #171f35;
  color: white !important;
}

.sc-jWActB button:hover {
  background: #10182c !important;
}

.sc-kPTPQs {
  color: white !important;
}

.sc-duSInm button {
  background: #151d33;
}

.sc-hCnrGf button:hover {
  color: white;
}

.sc-cNNTdL:before {
  box-shadow: none !important;
  opacity: 1;
}

.sc-cNNTdL span {
  color: white !important;
}

.sc-hssNwF:after {
  box-shadow: none !important;
}

.sc-kRayXq button {
  color: white !important;
}


.sc-fFGjHI span {
  background: #1c253e !important;
}

.sc-fFGjHI:before {
  background: #f5f5f53d !important;
}

.sc-fYfoDg {
  background: inherit !important;
  margin: 0;
  text-align: left !important;
  display: block !important;
  position: unset !important;
  padding: 0 !important;
  text-indent: 36px;
}

.sc-dNtQuv {
  overflow: visible;
}

.sc-dNtQuv {
  display: block !important;
}

.CookieConsent {
  background: #0b1221 !important;
  color: #8b9fba !important;
  padding: 2rem;
}

.CookieConsent h4 {
  color: white;
}

/* toast */

/* cookie notice */
#cookit {
  background: #0b0f21cf !important;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  padding: 22px 0px;
  z-index: 9999;
  left: 0;
  right: 0;
  height: 100%;
}
#cookit-container {
  position: absolute;
  top: 30%;
  margin: 0px auto;
  left: 0;
  right: 0;
  max-width: 720px;
  padding: 3rem 2rem;
  background: #080b1b;
  border-radius: 14px;
}
	#cookit p {
    color: #a7bac6 !important;
    display: inline-block;
    margin-bottom: 20px;
    font-size: 15px;
	}
  #cookit-message p i {
    display: block;
    color: #1576d5;
    font-size: 50px;
    margin-bottom: 1.4rem;
}
#cookit p em {
  text-decoration: underline;
  color: white;
  font-style: normal;
}
#cookit-button {
  padding: 12px 20px;
  color: white !important;
  cursor: pointer;
  background: linear-gradient(45deg, #20569c 40%, #1778a9 100%);
  background-size: 150%;
  border-radius: 36px;
  border: 0 !important;
  background-color: transparent !important;
  display: block;
  margin: 1rem;
}
#cookit-button:hover {
  background: #174d93 !important;
}
#cookit-link {
	padding: 0px 12px;
	color: #00a6f6 !important;
}
#cookit-link:hover {
  color: #1bcada !important;
}
.tokenomics-content img {
	border-radius: 12px;
	height: auto;
	max-width: 100%;
}

.tokenomics-content h2 {
	font-size: 22px;
	color: white;
	margin-bottom: 20px;
	background: #0b1123;
	padding: 16px 20px;
	border-radius: 9px;
	border-left: 4px solid #2ed2bc;
	font-weight: 400;
}

	.tokenomics-content h2 strong {
		font-weight: 500;
	}

.tokenomics-content h3 {
	font-size: 20px;
	margin-bottom: 20px;
	margin-top: 0;
}

	.tokenomics-content h3 strong {
		font-weight: 400;
	}

.lightMode .tokenomics-content h4 {
	color: #121212;
}

.sup-p sup {
	display: block;
	line-height: 1.5;
}

.sup-p {
	border-top: 1px solid #73737361;
	padding-top: 20px;
}

.lightMode .slider.slider-style-1:before {
	opacity: 0;
}

.lightMode .slider.slider-style-1:before {
}

.lightMode #section-slider:before {
	opacity: 0;
}


.tokenomics-content ul li {
	line-height: 1.5;
	font-size: 16px;
	margin-bottom: 8px;
}

.tokenomics-content ul {
	margin-bottom: 2.5rem !important;
	display: block;
}

	.tokenomics-content ul li:before {
		content: "";
		width: 14px;
		height: 14px;
		background: #28d1ba;
		border-radius: 2px;
		display: inline-block;
		margin-right: 8px;
		top: 2px;
		position: relative;
	}

.tokenomics-content h4 {
	font-size: 18px;
	margin-bottom: 12px;
	color: white;
	font-weight: 400 !important;
}

	.tokenomics-content h4 strong {
		font-weight: 500 !important;
	}

.tokenomics-content h5 {
	font-size: 16px;
	margin-bottom: 12px;
	color: white;
}

.tokenomics-content p strong, .tokenomics-content li strong {
	font-weight: 400;
}

.cookiehidden {
	visibility: hidden;
	opacity: 0;
}
/* cookie notice */
@media (max-width: 1180px) {
  header {
    padding: 20px 0;
  }
}

@media (min-width:1366px) {
  .container {
    max-width: 1240px;
  }
}

@media (min-width:1400px) {
  .container {
    max-width: 1320px;
  }
}

@media (min-width: 960px) {
  .date-filter {
    align-items: center !important;
    justify-content: flex-start !important;
    display: flex !important;
  }

  .filter-title {
    background: #9614C4;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
  .card-style-1 {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 768px) and (orientation: portrait) {
  .card-style-1 {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .card-style-1 {
    margin-bottom: 1rem;
  }
}

@media (min-width: 1024px) and (max-width: 1365px) {
  body {
    background-size: cover;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  header .container-fluid {
    padding: 0px 26px;
  }
}

@media (max-width: 1024px) {
  header {
    padding: 20px 0;
  }

  .offcanvas-menu .nav-item a:before {
    opacity: 0;
    display: none;
  }
}

@media (min-width: 861px) {
  .balance-column {
    justify-content: flex-end !important;
    align-items: center !important;
    display: flex !important;
  }

  /* Bond list */
  .bond-list ul li {
    padding: 0px 2.8rem;
    background: #060c1dde;
    width: 25%;
    color: white;
    vertical-align: middle;
    border-right: 1px solid #00000047;
    height: 92px;
    display: inline-flex !important;
    align-items: center !important;
  }

  .xs-title {
    display: none;
  }

  .bond-list-header li {
    height: 80px !important;
    background: #0b1331e6 !important;
  }

  .bond-list ul li:first-child {
    width: 30%;
  }

  .bond-list ul li:last-child {
    border-right: 0;
    width: 20%;
    padding-right: 0;
  }

  .bond-list ul {
    padding: 0;
  }

  .bond-list-body ul:nth-of-type(even) li {
    background: #091126e0;
  }

  .bond-list-header li:last-child {
    border-top-right-radius: 19px;
  }

  .bond-list-header li:first-child {
    border-top-left-radius: 19px;
  }

  .bond-type {
    justify-content: flex-start !important;
    align-items: center !important;
    display: flex !important;
  }

  .asset-image {
    width: 50px;
    text-align: center;
  }

  .asset-image img {
    max-width: 100%;
    height: auto;
  }

  .bond-type img {
    margin-right: 16px;
  }

  .asset-ticker p {
    margin: 0;
  }

  /* my bonds - claim */
  .my-bonds .bond-list-body ul li:last-child p {
    margin: 0;
  }

  .my-bonds ul li:first-child {
    width: 20%;
  }

  .my-bonds ul li {
    width: 20%;
  }

  /* my bonds - claim */
  /* Bond list */
}

@media (min-width: 861px) and (max-width: 1024px) {
  .bond-list ul li {
    padding: 0px 1rem;
  }

  .bond-list .button-primary {
    padding: 0px 12px;
  }
}

@media (max-width: 860px) {
  .faq-accordion .accordion-header button {
    font-size: 14px;
    padding: 18px 10px;
  }

  .balance-column {
    text-align: left;
  }

  .balance-column button {
    display: block;
    width: 100%;
    margin-top: 1rem;
  }

  .balance-left ul {
    text-align: left;
    display: block !important;
  }

  .balance-left ul li,
  .balance-column ul li {
    justify-content: space-between !important;
    display: flex;
    align-items: center;
    line-height: 2.5;
    border-bottom: 1px solid #9ab6d00d;
    font-size: 13px;
  }

  /* bond list mobile */

  .bond-list .button-primary {
    padding: 14px 0;
    min-width: 138px;
  }

  .bond-list-header {
    display: none;
  }

  .bond-list-body ul {
    margin-bottom: 2rem;
    padding: 2rem;
    background: #091126e0;
    color: white;
    text-align: center;
  }

  .bond-type {
    margin-top: -50px;
    color: white;
  }

  .bond-list .bond-list-body ul li span {
    display: block;
  }

  .bond-list .bond-list-body ul li {
    padding-bottom: 12px;
    border-bottom: 1px solid #0c1837;
    margin-bottom: 12px;
    color: #d8d5d5;
  }

  .bond-list .bond-list-body ul li .xs-title {
    font-size: 14px;
  }

  .bond-list .bond-list-body ul li:last-child {
    border: 0;
    margin: 0;
  }

  .asset-ticker p {
    font-weight: 700;
    font-size: 18px;
    margin: 24px 0 0px 0;
  }

  .asset-ticker {
    margin-bottom: 1rem;
  }

  .bond-list-body .button-primary {
    margin-top: 2rem;
  }

  .my-bonds ul li {
    padding: 0 1rem;
  }

  /* bond list mobile */
  .footer {
    text-align: center;
  }

  .card-value {
    font-size: 19px;
    color: white;
  }

  .bond-list-header {
    display: none;
  }

  body:before {
    width: 140%;
  }

  .card-style-1,
  .card-style-2 {
    margin-bottom: 1rem;
  }

  .date-filter {
    background: transparent;
  }

  .filter-title {
    padding: 0px 10px;
    display: none;
  }

  .filter-options {
    padding-left: 10px;
  }

  .filter-options {
    background: #061526;
    border-radius: 16px;
  }

  .filter-options li button {
    font-size: 14px;
  }

  .version-column {
    display: none;
  }
}

@media (max-width: 520px) {
  body {
    background-position: 0 0 !important;
  }
  .faq-accordion .accordion-header button {
    padding: 18px 35px 18px 10px;
}
}

@media (max-width: 390px) {
  .filter-options li button {
    padding: 0 10px;
  }
}